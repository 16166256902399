<template>
  <footer class="page-footer">
    <div class="page-footer__circle"></div>
    <div class="page-footer__items">
      <div class="page-footer__item">
        <h3 class="page-footer__sub-title">
          {{ $t("nav.about") }}
        </h3>
        <div class="page-footer__sub-links">
          <localized-link to="/architecture" class="page-footer__sub-link">
            {{ $t("nav.architecture") }}
          </localized-link>
          <localized-link to="/improvement" class="page-footer__sub-link">
            {{ $t("nav.improvement") }}
          </localized-link>
          <localized-link to="/parking" class="page-footer__sub-link">
            {{ $t("nav.parking") }}
          </localized-link>
          <localized-link to="/location" class="page-footer__sub-link">
            {{ $t("nav.location") }}
          </localized-link>
          <localized-link to="/developer" class="page-footer__sub-link">
            {{ $t("nav.developer") }}
          </localized-link>
          <localized-link to="/documentation" class="page-footer__sub-link">
            {{ $t("nav.documentation") }}
          </localized-link>
        </div>
      </div>
      <div class="page-footer__item">
        <h3 class="page-footer__sub-title">
          {{ $t("nav.flats") }}
        </h3>
        <div class="page-footer__sub-links">
          <localized-link
            :to="{ path: '/flats' }"
            class="page-footer__sub-link"
            :class="{
              'page-footer__sub-link--active':
                $route.name == 'flats' && $route.query.layouts != '1'
            }"
          >
            {{ $t("nav.floor") }}
          </localized-link>
          <localized-link
            :to="{ name: 'flats', query: { layouts: 1 } }"
            class="page-footer__sub-link"
            :class="{
              'page-footer__sub-link--active':
                $route.name == 'flats' && $route.query.layouts == '1'
            }"
          >
            {{ $t("nav.layout") }}
          </localized-link>
        </div>
      </div>
      <div class="page-footer__item">
        <h3 class="page-footer__sub-title">
          {{ $t("nav.contact_us") }}
        </h3>
        <div class="page-footer__sub-links">
          <localized-link to="/contacts" class="page-footer__sub-link">
            {{ $t("nav.contacts") }}
          </localized-link>
        </div>
      </div>
    </div>

    <div class="page-footer__links-wrap">
      <div v-if="phone" class="page-footer__phone-wrapper">
        <a class="page-footer__phone" :href="`tel:${phoneNumber}`">
          {{ phone }}
        </a>
        <p class="page-footer__phone-text">
          {{ $t("pages.contacts.address_caption") }}
        </p>
      </div>

      <div v-if="social.length" class="page-footer__socials">
        <a
          v-for="link in social"
          :key="link.key"
          class="page-footer__social"
          :href="link.link"
          rel="nofollow noreferrer"
          target="_blank"
        >
          <svg-icon width="18" height="18" class="btn-icon" :name="link.key" />
        </a>
      </div>
    </div>

    <div class="page-footer__doc-links">
      <localized-link
        to="/privacy"
        class="page-footer__doc-link page-footer__doc-link--privacy"
      >
        {{ $t("nav.privacy") }}
      </localized-link>
      <localized-link
        to="/cookies"
        class="page-footer__doc-link page-footer__doc-link--cookie"
      >
        {{ $t("nav.cookies") }}
      </localized-link>
    </div>
    <div class="page-footer__copyright-inner">
      <div class="page-footer__text">
        ©
        {{ new Date().getFullYear() }}
        Panorama
      </div>
      <div class="page-footer__copyright">
        {{ $t("nav.creation") }}
        <a
          rel="nofollow noreferrer"
          target="_blank"
          class="page-footer__copyright-link"
        >
          <svg-icon
            width="71"
            height="18"
            name="cybeam"
            class="page-footer__devs-logo"
          />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    contacts() {
      return this.$store.state.contacts;
    },
    social() {
      return this.contacts.social || [];
    },
    phone() {
      return this.contacts.phone || "";
    },
    phoneNumber() {
      return this.$store.getters.phoneNumber;
    }
  },
  methods: {}
};
</script>

<style scoped lang="less">
.page-footer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  max-width: 2500px;
  width: 100%;
  margin: auto auto 0;
  padding: 100px 50px 50px;
  @media @bw1170 {
    padding: 80px 30px 30px;
  }
  @media @bw768 {
    flex-direction: column;
    padding: 70px 20px 30px;
  }
  &__items {
    display: flex;
    width: calc(100% - 400px);
    margin-bottom: 90px;
    @media @bw1510 {
      width: calc(100% - 251px);
    }
    @media @bw1170 {
      margin-bottom: 70px;
      width: 100%;
    }
    @media @bw768 {
      flex-direction: column;
      margin-bottom: 60px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
    @media @bw1510 {
      margin-right: 70px;
    }
    @media @bw1170 {
      margin-right: 60px;
    }
    @media @bw768 {
      margin-right: 0;
      margin-bottom: 50px;
    }
    &:last-child {
      margin-right: 0;
      @media @bw768 {
        margin-bottom: 0px;
      }
    }
  }
  &__sub-title {
    margin: 0 0 30px;
    font-family: @font2;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1;
    color: @white;
  }
  &__sub-link {
    display: block;
    margin: 0 0 24px;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    font-size: 1.25em;
    color: @white_60;
    transition: color 0.2s;
    &:hover {
      color: @gold;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.router-link-exact-active {
      cursor: default;
      color: @gold;
      pointer-events: none;
      &:hover {
        color: @gold;
      }
    }
  }
  &__links-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 400px;
    @media @bw1510 {
      width: 251px;
    }
    @media @bw1170 {
      align-items: flex-start;
      margin-bottom: 40px;
      margin-right: auto;
    }
    @media @bw768 {
      margin-right: 0;
      margin-bottom: 60px;
      width: 100%;
    }
  }
  &__phone-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    @media @bw1170 {
      align-items: flex-start;
      text-align: left;
    }
  }
  &__phone {
    margin: 0 0 10px;
    display: block;
    text-decoration: none;
    font-family: @font2;
    font-weight: 700;
    font-size: 72px;
    line-height: 0.92;
    color: @white;
    @media @bw1510 {
      font-size: 48px;
      line-height: 1;
    }
  }
  &__phone-text {
    margin: 0 0 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    color: @white_60;
  }
  &__socials {
    display: flex;
  }
  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    width: 40px;
    height: 40px;
    background-color: @white_10;
    border-radius: 2px;
    color: @white;
    transition: color 0.2s;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: @gold;
    }
  }
  &__copyright-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media @bw400 {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  &__text {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.6;
    color: @white;
    @media @bw400 {
      order: 2;
    }
  }
  &__copyright {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.6;
    color: @white_60;
    @media @bw400 {
      margin-bottom: 20px;
      order: 1;
    }
  }
  &__copyright-link {
    display: flex;
    margin-left: 10px;
  }
  &__devs-logo {
    display: block;
    margin-top: -1px;
    color: @gold;
  }
  &__doc-links {
    display: flex;
    position: absolute;
    left: 360px;
    bottom: 54px;
    @media @bw1170 {
      position: static;
      align-items: flex-end;
      flex-direction: column;
      margin-bottom: 50px;
    }
    @media @bw768 {
      align-items: flex-start;
      margin-bottom: 60px;
    }
  }
  &__doc-link {
    display: block;
    margin-right: 30px;
    text-decoration-color: @white;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.6;
    color: @white;
    transition: text-decoration-color 0.2s;
    &:hover {
      text-decoration-color: transparent;
    }
    @media @bw1170 {
      margin-right: 0;
      margin-bottom: 24px;
    }
    &:last-child {
      @media @bw1170 {
        margin-bottom: 0;
      }
    }
    &--privacy {
      margin-right: 0;
      order: 2;
      @media @bw1170 {
        order: 1;
      }
    }
    &--cookie {
      order: 1;
      @media @bw1170 {
        order: 2;
      }
    }
    &.router-link-exact-active {
      cursor: default;
      text-decoration-color: transparent;
      pointer-events: none;
      &:hover {
        text-decoration-color: transparent;
      }
    }
  }
  &__circle {
    position: absolute;
    right: 400px;
    bottom: 0;
    height: 30px;
    width: 430px;
    overflow: hidden;
    @media @bw1510 {
      right: 160px;
      height: 25px;
    }
    @media @bw1170 {
      right: auto;
      left: 120px;
    }
    @media @bw768 {
      left: auto;
      right: 0;
      bottom: 335px;
      width: 40px;
      height: 430px;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 430px;
      height: 430px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @gold;
      @media @bw768 {
        left: 0;
        right: auto;
      }
    }
  }
}
</style>
