export const observer = {
  data() {
    return {
      observer: null
    };
  },
  methods: {
    observe() {
      const isObservable = this.$el.querySelectorAll(
        ".is-observable:not(.is-observed)"
      );
      if (this.observer) {
        isObservable.forEach((el) => {
          this.observer.observe(el);
        });
      } else {
        isObservable.forEach((el) => {
          el.classList.remove("is-observable");
        });
      }
    },
    onObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }
        this.observer.unobserve(target);
        target.classList.add("is-observed");
        target.classList.remove("is-observable");
      });
    }
  },
  created() {
    if ("IntersectionObserver" in window)
      this.observer = new IntersectionObserver(this.onObserved, {
        root: this.$el,
        rootMargin: "0px",
        threshold: 0.1
      });
  },
  mounted() {
    this.$nextTick(() => {
      this.observe();
    });
  },
  beforeUnmount() {
    this.observer.disconnect();
  }
};
