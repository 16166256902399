export const pageLocale = {
  data() {
    return {
      loadQty: 2, // смонтировано + перевод
      loaded: 0,
      isLoaded: false,
      pageLocale: null
    };
  },
  computed: {
    locale: function locale() {
      return this.$i18n.locale;
    }
  },
  methods: {
    getPageLocale() {
      this.$axios({
        method: "get",
        url:
          "/locales/" +
          this.locale +
          "/" +
          this.$options.name +
          ".json" +
          "?hash_id=" +
          Math.random(),
        timeout: 90000
      })
        .then((response) => {
          if (response && response.data) {
            this.pageLocale = response.data;
          } else {
            this.$toast.error(this.$t("error"));
          }
          this.$nextTick(() => {
            this.loaded = this.loaded + 1;
          });
        })
        .catch((error) => {
          this.$toast.error(this.$t("error"));
          console.log(error && error.response);
          this.$nextTick(() => {
            this.loaded = this.loaded + 1;
          });
        });
    }
  },
  created() {
    this.getPageLocale();
  },
  mounted() {
    this.$nextTick(() => {
      this.loaded = this.loaded + 1;
    });
  },
  watch: {
    locale() {
      this.getPageLocale();
    },
    loaded: {
      handler: function (val) {
        if (val >= this.loadQty && !this.isLoaded) {
          this.isLoaded = true;

          if (this.observer) {
            this.observe();
          }

          this.$emitter.emit("hide-preloader");
          document.dispatchEvent(new Event("render-event"));
        }
      },
      immediate: true
    }
  }
};
